<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Activación de tarjeta</h5>
				<div class="p-fluid formgrid grid">
					<div class="field col-12 md:col-4">
						<label for="name">Nombre</label>
						<InputText id="name" type="text" v-model="model.sNombre"/>
						<small class="p-invalid" v-if="submitted && !model.sNombre">El Nombre es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sNombre.length > 100">Solo se permiten 100 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateNames(model.sNombre)">El Nombre solo acepta letras y espacios.</small>
					</div>
					<div class="field col-12 md:col-4">
						<label for="lastname">Apellido Paterno</label>
						<InputText id="lastname" type="text" v-model="model.sApellidoPaterno" />
						<small class="p-invalid" v-if="submitted && !model.sApellidoPaterno">El Apellido Paterno es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sApellidoPaterno.length > 100">Solo se permiten 100 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateNames(model.sApellidoPaterno)">El Apellido Paterno solo acepta letras y espacios.</small>
					</div>
					<div class="field col-12 md:col-4">
						<label for="lastname2">Apellido Materno</label>
						<InputText id="lastname2" type="text" v-model="model.sApellidoMaterno"/>
						<small class="p-invalid" v-if="submitted && !model.sApellidoMaterno">El Apellido Materno es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sApellidoMaterno.length > 100">Solo se permiten 100 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateNames(model.sApellidoMaterno)">El Apellido Materno solo acepta letras y espacios.</small>
					</div>

					<div class="field col-12 md:col-4">
						<label for="email">Correo Electrónico</label>
						<InputText id="email" type="text" v-model="model.sEmail"/>
						<small class="p-invalid" v-if="submitted && !model.sEmail">El Email es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sEmail.length > 60">Solo se permiten 60 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateEmail(model.sEmail)">Email incorrecto.</small>
						
					</div>
					<div class="field col-12 md:col-4">
						<label for="phone">Teléfono</label>
						<InputText id="phone" type="text" v-model="model.sTelefono"/>
						<small class="p-invalid" v-if="submitted && !model.sTelefono">El Telefono es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sTelefono.length > 10">Solo se permiten 10 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateNumbersOnly(model.sTelefono)">El telefono solo permite Números.</small>
						
					</div>
                    <div class="field col-12 md:col-3">
						<label for="card">Código Postal</label>
						<InputText id="card" type="text" v-model="model.sCP"/>
						<small class="p-invalid" v-if="submitted && !model.sCP">El CP es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sCP.length > 5">Solo se permiten 5 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateNumbersOnly(model.sCP)">El CP solo permite Números.</small>
					</div>
                    <div class="field col-12 md:col-3">
						<label for="gender">Sexo</label>
						<Dropdown id="gender" v-model="sexo" :options="dropdownItems" optionLabel="name" placeholder="Elije una Opción"></Dropdown>
						<small class="p-invalid" v-if="submitted && !sexo.code">El Sexo es requerido.</small>
					</div>
					<div class="field col-12 md:col-3">
                        <label for="birthday">Fecha de Nacimiento</label>
						<InputMask id="birthday" mask="9999/99/99" v-model="model.dFechaNacimiento" placeholder="Año mes y dia" slotChar="yyyy/mm/dd" />
						<small class="p-invalid" v-if="submitted && !model.dFechaNacimiento">La Fecha de Nacimiento es requerido.</small>
					</div>
                    <div class="field col-12 md:col-3">

						<label for="card">Tarjeta</label>
						<InputText id="card" type="text" v-model="model.sNoTarjeta" placeholder="Tajeta" />
						<small class="p-invalid" v-if="submitted && !model.sNoTarjeta">La tarjeta es requerido.</small>
						<small class="p-invalid" v-if="submitted && model.sNoTarjeta.length > 20">Solo se permiten 20 caracteres máximo.</small>
						<small class="p-invalid" v-if="submitted && !validateNumbersOnly(model.sNoTarjeta)">La tarjeta solo permite Números.</small>
						
					</div>
				</div>                    
                <Button label="Aceptar" style="float: right" class="p-button-rounded mr-2 mb-2" @click="saveData" />    
				<Button label="Regresar" style="float: right" class="p-button-rounded p-button-secondary mr-2 mb-2" @click="this.$router.push('/dashboard')" />                    
					<Button label="Generar Tarjeta Virtual"  class="p-button-rounded mr-2 mb-2" @click="generateVirtualCard" />                    
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
	export default {
		data() {
			return {
				dropdownItems: [
					{name: 'Masculino', code: 'M'},
					{name: 'Femenino', code: 'F'},
				],
                sexo: 'Masculino',
				submitted: false,
                model: {
                    sUsuario: '', 
                    sNoTarjeta: '',
                    sNombre: '', 
                    sApellidoPaterno: '',
                    sApellidoMaterno: '', 
                    sTelefono: '', 
                    sEmail: '',
                    sSexo: '', 
                    dFechaNacimiento: '',
                    sCP: '' 

                },
				dropdownItem: null
			}
		},
		mounted() {
			this.isTokenExpired()
		},
        methods: {
			validateNames(sNames){
					return sNames.match(
						/^[a-zA-Z ]{1,100}$/
					);

			},
			validateEmail(email){
					return email.match(
						/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					);

			},
			validateNumbersOnly(sNumbers){
					return sNumbers.match(
						/^\d+$/
					);

			},
			validateForm(){
				
				if(this.model.sNoTarjeta.length <= 0)
					return false
				
				if(this.model.sNombre.length <= 0)
					return false					

				if(this.model.sApellidoPaterno.length <= 0)
					return false

				if(this.model.sApellidoMaterno.length <= 0)
					return false

				if(this.model.sTelefono.length <= 0)
					return false

				if(this.model.sEmail.length <= 0)
					return false

				if(this.sexo.code.length <= 0)
					return false



				if(this.model.dFechaNacimiento.length <= 0)
					return false


				if(this.model.sCP.length <= 0)
					return false




				if(this.model.sNoTarjeta.length > 20)
					return false
				
				if(this.model.sNombre.length > 100)
					return false					

				if(this.model.sApellidoPaterno.length > 100)
					return false

				if(this.model.sApellidoMaterno.length > 100)
					return false

				if(this.model.sTelefono.length > 10)
					return false

				if(this.model.sEmail.length > 60)
					return false

				if(this.model.sCP.length > 5)
					return false
				
				console.log('Uno')
					
				if(!this.validateEmail(this.model.sEmail))
					return false
				
				console.log('Dos')

				if(!this.validateNumbersOnly(this.model.sTelefono))
					return false
				
				console.log('Tres')

				if(!this.validateNumbersOnly(this.model.sNoTarjeta))
					return false
				
				console.log('Cuatro')
					
				if(!this.validateNumbersOnly(this.model.sCP))
					return false
				
				console.log('Cinco')
				
				if(!this.validateNames(this.model.sNombre) || !this.validateNames(this.model.sApellidoPaterno) || !this.validateNames(this.model.sApellidoMaterno))
					return false
				
				console.log('Seis')
				

				return true
			},
			async generateVirtualCard(){
				let response = false
				await Swal.fire({
					title: '¿Está seguro de que desea generar una tarjeta Digital.?',
					showDenyButton: true,
					confirmButtonText: 'Si',
					denyButtonText: 'No',
				}).then((result) => {
					response = result.isConfirmed
				})

				let idUser = JSON.parse(localStorage.getItem("dataPerson")).idUser;
				if (response) {
					this.openLoading('Generando Tarjeta...')
					await axios.get(apiURL + 'CirculoSaludOro/getVirtualCard?idRegisterUser=' + idUser, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
					.then( (response) => {
						Swal.close()
						if(response.data.huboError == true){
							Swal.fire({
								icon: 'error',
								title: 'Error',
								text: response.data.mensajeError,
								footer: ''
							})	
							return
						}
						this.model.sNoTarjeta = response.data.membresia
					} )
					.catch( (error) => {
						Swal.close()
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: error,
							footer: ''
                    	})
					} )
					Swal.close()
					Swal.fire('No. de tarjeta: ' + this.model.sNoTarjeta, '', 'success')
				} else {
					Swal.fire('No se generó tarjeta', '', 'info')
				}
			},
            async saveData(){
				this.submitted = true;
				
				if(!this.validateForm())
					return

				this.model.sSexo = this.sexo.code
				this.model.sUsuario = String(JSON.parse(localStorage.getItem("dataPerson")).idUser)

				console.log('Datos: ' + JSON.stringify(this.model))

				this.openLoading('Activando Tarjeta...')

				await axios.post(apiURL + 'CirculoSaludOro/activateCard', this.model, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
					Swal.close()
                    if(response.data.huboError == true){
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: response.data.mensajeError,
							footer: ''
						})						
                        return
                    }
                    Swal.fire('Datos Guardados correctamente')
					this.clearForm()
                } )
                .catch( (error) => {
					Swal.close()
					Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                } )                
            },
			async clearForm(){
				this.model.sUsuario= ''
				this.model.sNoTarjeta= ''
				this.model.sNombre= ''
				this.model.sApellidoPaterno= ''
				this.model.sApellidoMaterno= ''
				this.model.sTelefono= ''
				this.model.sEmail= ''
				this.model.dFechaNacimiento= ''
				this.model.sSexo= ''
				this.model.dFechaNacimiento= ''
				this.model.sCP= ''
			}
        }
	}
</script>        